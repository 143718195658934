@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Manrope", sans-serif;
}

a {
  color: white;
  text-decoration: none;
}

.Header-content {
  background: #161515;
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 95;
}

.Header {
  z-index: 99;
  width: 92%;
  color: white;
  background: #242424;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  margin-top: 1.2rem;
  margin-left: 4%;
  font-size: 0.9rem;
  border-radius: 10px;
}
.Header-logo {
  margin-left: 3rem;
}
.Header-logo h2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header-logo h2::after {
  content: "beta";
  font-weight: 100;
  font-size: 0.8rem;
  margin-top: 21px;
  margin-left: 2px;
  color: rgba(210, 210, 206, 0.862745098);
}
.Header-nav {
  margin-right: 3rem;
}
.Header-nav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 30px;
  cursor: pointer;
}
.Header-nav ul li:hover {
  transition: 0.5s;
}
.Header-nav ul li:active {
  filter: blur(3px);
}

.on-link {
  transition: 0.5s;
  color: #b05dff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solid-on {
  display: none;
}

.solid {
  display: flex;
}

.Header-mostrar {
  display: flex;
}

.items-link {
  display: none;
}

@media (max-width: 700px) {
  .rotateLevt {
    transform: rotate(180deg);
    transition: 0.75s;
  }
  .rotateNormal {
    transform: rotate(0deg);
    transition: 0.75s;
  }
  .solid-on {
    display: none;
  }
  .Header-logo {
    margin-left: 2rem;
  }
  .Header-nav {
    margin-right: 2rem;
  }
  .items-link {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #201f1f;
    gap: 5px;
    border-radius: 10px;
    padding: 5px;
    margin-top: 10px;
  }
  .items-link li {
    list-style: none;
    background-color: #242424;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    border-radius: 10px;
  }
  .items-link li a:active {
    user-select: none;
    opacity: 0.2;
  }
}

