$colortext: white;

*{
    user-select: none;
}


.Home{
    &-Section1{
        color: $colortext;
        width: 92%;
        margin-left: 4%;
        height: 800px;
        display: grid;
        
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas: "welcome welcome welcome welcome card1 card2"
                             "welcome welcome welcome welcome card1 card2"
                             "welcome welcome welcome welcome card1 card2"
                             "small1  small2  small3  small4  text1 text2"
                             "small1  small2  small3  small4  text1 text2";
        gap: 10px;

        &-welcome{
            grid-area: welcome;
            background: url("../../img/bienvenido.jpeg");
            background-size: cover;
            background-position: bottom;
            &-contain{
                /*border: solid red;*/
                max-width: 600px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                & ul{
                    display: flex;
                    font-size:.8rem;
                    margin: 2rem;
                    color: #d5d5d5;
                }
                & ul li{
                    list-style: none;
                    cursor: pointer;
                }
                & ul li:hover{
                    text-decoration: underline;
                }
                & .text-reference{
                    /*border: solid blue;*/
                    margin: 2rem;
                    & h1{
                        font-size: 3rem;
                    }
                    & button{
                        border: 1px solid $colortext;
                        background: none;
                        padding: 6px;
                        cursor: default;
                    }
                    & button a{
                        color: $colortext;
                    }
                    & button:hover{
                        transition: 0.5s;
                        background: white;
                        color: black;

                    }
                    & button a:hover{
                        color: black;
                    }
                }
            }
        }
        &-card1{
            grid-area: card1;
            background: url("../../img/card1.jpeg");
            background-size: cover;
            background-position: center;
        }
        &-card2{
            grid-area: card2;
            background: url("../../img/card2.jpeg");
            background-size: cover;
            background-position: center;
        }
        &-small1{
            grid-area: small1;
            /*border: solid gold;*/
            
            background: url("../../img/small1.jpeg");
            background-size: cover;
            background-position: center;
        }
        &-small2{
            grid-area: small2;

            
            background: url("../../img/small2.gif");
            background-size: cover;
            background-position: center;
        }
        &-small3{
            grid-area: small3;
            background: url("../../img/small3.jpeg");
            background-size: cover;
            background-position: center;
        }
        &-small4{
            grid-area: small4;
            background: url("../../img/small4.gif");
            background-size: cover;
            background-position: center;

        }
        &-text1{
            grid-area: text1;
            background: #222;
        }
        &-text2{
            grid-area: text2;
            background: #222;
        }
    }

}

@media (max-width: 700px) {
    .Home{
        &-Section1{
            color: $colortext;
            width: 92%;
            margin-left: 4%;
            height: 900px;
            display: grid;
            gap: 0px;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-template-areas: "welcome welcome welcome welcome welcome welcome"
                                 "welcome welcome welcome welcome welcome welcome"
                                 "welcome welcome welcome welcome welcome welcome"
                                 "welcome welcome welcome welcome welcome welcome"
                                 "card1    card1  card1   card2   card2   card2  "
                                 "card1    card1  card1   card2   card2   card2  "
                                 "small1  small1   small1  small1 small1  small1 "
                                 "small2  small2   small2  small2 small2  small2 "
                                 "small3  small3   small3  small4 small4  small4 ";
            
            grid-row-gap: 10px;
            &-welcome{
                grid-area: welcome;
                background: url("../../img/bienvenido.jpeg");
                background-size: cover;
                background-position: top;
                &-contain{
                    max-width: 600px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    & ul{
                        display: flex;
                        font-size:.8rem;
                        margin: 2rem;
                        color: #d5d5d5;

                    }
                    & ul li{
                        list-style: none;
                        cursor: pointer;
                    }
                    & ul li:hover{
                        text-decoration: underline;
                    }
                    & .text-reference{
                        margin: 1rem;
                        & h1{
                            font-size: 3rem;
                        }
                        & button{
                            border: 1px solid $colortext;
                            background: none;
                            padding: 6px;
                            cursor: default;
                        }
                        & button a{
                            color: $colortext;
                        }
                        & button:hover{
                            transition: 0.5s;
                            background: white;
                            color: black;
    
                        }
                        & button a:hover{
                            color: black;
                        }
                    }
                }
            }
            
        &-card1{
            grid-area: card1;
            background: url("../../img/card1.jpeg");
            background-size: cover;
            background-position: center;
            margin-right: 5px;
        }
        &-card2{
            grid-area: card2;
            background: url("../../img/card2.jpeg");
            background-size: cover;
            background-position: center;
            
            margin-left: 5px;
        }
        &-small1{
            grid-area: small1;
            
            background: url("../../img/small1.jpeg");
            background-size: cover;
            background-position: center;
        }
        &-small2{
            grid-area: small2;

            
            background: url("../../img/small2.gif");
            background-size: cover;
            background-position: center;
        }
        &-small3{
            grid-area: small3;
            background: url("../../img/small3.jpeg");
            background-size: cover;
            background-position: center;
        }
        &-small4{
            grid-area: small4;
            background: url("../../img/small4.gif");
            background-size: cover;
            background-position: center;

        }
        &-text1{
            grid-area: text1;
            background: #222;
        }
        &-text2{
            grid-area: text2;
            background: #222;
        }
        }
    
    }
  }
  