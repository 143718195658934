.text-special {
  color: rgb(240, 196, 83);
  font-size: 1.2rem;
  text-transform: uppercase;
}

.Blog-Section1 {
  color: white;
  /*border: solid red;*/
  width: 92%;
  height: 800px;
  margin-left: 4%;
  display: grid;
  gap: 0.5rem;
  grid-template-areas: "content static";
  grid-template-columns: 70% 30%;
}
.Blog-Section1-Content {
  border: solid green;
  background: rgb(3, 7, 18);
  border: 1px solid rgb(17, 24, 39);
  grid-area: content;
}
.Blog-Section1-Content .text-all {
  margin: 3rem;
  /*border: solid red;*/
}
.Blog-Section1-Content .text-all h1 {
  margin-top: 1rem;
  font-size: 2.3rem;
}
.Blog-Section1-Content .text-all .sub-title {
  color: #5c5c5c;
}
.Blog-Section1-Content .text-all .autor {
  color: #a54dd1;
}
.Blog-Section1-Content .text-all .img {
  /*border: solid brown;*/
  margin-top: 1rem;
  width: 50%;
  height: 250px;
  background: url("../../img/deno.gif");
  background-position: center;
  background-size: cover;
}
.Blog-Section1-Content .text-all .text {
  margin-top: 2rem;
  width: 80%;
  color: #b4b1b1;
}
.Blog-Section1-static {
  /*border: solid purple;*/
  grid-area: static;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.Blog-Section1-static .Blog-card {
  cursor: default;
  background: rgb(3, 7, 18);
  border: 1px solid rgb(17, 24, 39);
  padding: 0.8rem;
  border-radius: 10px;
}
.Blog-Section1-static .Blog-card h3 {
  font-size: 1.2rem;
  font-weight: 300;
}
.Blog-Section1-static .Blog-card div {
  display: flex;
  justify-content: space-between;
  color: #5c5c5c;
}
.Blog-Section1-static .Blog-card div button {
  border: none;
  background: rgb(64, 64, 233);
  color: white;
  padding: 0.3rem;
}
.Blog-Section1-static .Blog-card div .purple {
  background: #a54dd1;
}
.Blog-Section1-static .Blog-card div p {
  font-size: 0.8rem;
  font-weight: 300;
}
.Blog-Section1-static .Blog-card p {
  font-size: 0.8rem;
}
.Blog-Section1-static .Blog-card .autor {
  color: #a54dd1;
  display: flex;
  align-items: center;
}
.Blog-Section1-static .Blog-card:hover {
  transition: 0.2s;
  padding: 0.9rem;
  opacity: 0.6;
}

@media (max-width: 700px) {
  .Blog-Section1 {
    color: white;
    /*border: solid red;*/
    width: 92%;
    height: 800px;
    margin-left: 4%;
    display: grid;
    gap: 0.5rem;
    grid-template-areas: "content content" "static static";
    grid-template-columns: 70% 30%;
  }
  .Blog-Section1-Content {
    border: solid green;
    background: rgb(3, 7, 18);
    border: 1px solid rgb(17, 24, 39);
    grid-area: content;
  }
  .Blog-Section1-Content .text-all {
    margin: 0.8rem;
    /*border: solid red;*/
  }
  .Blog-Section1-Content .text-all h1 {
    margin-top: 1rem;
    font-size: 2.3rem;
  }
  .Blog-Section1-Content .text-all .sub-title {
    color: #5c5c5c;
  }
  .Blog-Section1-Content .text-all .autor {
    color: #a54dd1;
  }
  .Blog-Section1-Content .text-all .img {
    /*border: solid brown;*/
    margin-top: 1rem;
    width: 90%;
    height: 250px;
    background: url("../../img/deno.gif");
    background-position: center;
    background-size: cover;
  }
  .Blog-Section1-Content .text-all .text {
    margin-top: 2rem;
    width: 95%;
    color: #b4b1b1;
  }
  .Blog-Section1-static {
    /*border: solid purple;*/
    grid-area: static;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .Blog-Section1-static .Blog-card {
    cursor: default;
    background: rgb(3, 7, 18);
    border: 1px solid rgb(17, 24, 39);
    padding: 0.8rem;
    border-radius: 10px;
  }
  .Blog-Section1-static .Blog-card h3 {
    font-size: 1.2rem;
    font-weight: 300;
  }
  .Blog-Section1-static .Blog-card div {
    display: flex;
    justify-content: space-between;
    color: #5c5c5c;
  }
  .Blog-Section1-static .Blog-card div button {
    border: none;
    background: rgb(64, 64, 233);
    color: white;
    padding: 0.3rem;
  }
  .Blog-Section1-static .Blog-card div .purple {
    background: #a54dd1;
  }
  .Blog-Section1-static .Blog-card div p {
    font-size: 0.8rem;
    font-weight: 300;
  }
  .Blog-Section1-static .Blog-card p {
    font-size: 0.8rem;
  }
  .Blog-Section1-static .Blog-card .autor {
    color: #a54dd1;
    display: flex;
    align-items: center;
  }
  .Blog-Section1-static .Blog-card:hover {
    transition: 0.2s;
    padding: 0.9rem;
    opacity: 0.6;
  }
}

