$text-color:white;
$margin: 2rem;
.Contact-Section1{
    color:$text-color;
    /*border: solid red;*/
    width: 92%;
    margin-left: 4%;

    
    & h2{
        margin-left: $margin;

    }
    & form{
        /*border: solid green;*/
        margin: 2rem;
        width: 80%;
        & fieldset input{
            border-radius: 20px;
            width: 80%;
        }
        & fieldset .style-input{
            
            width: 80%;
            padding: 10px;
            outline: none;
            border: 1px  solid $text-color;
            margin: 10px;
            background: none;
            color: $text-color;
        }
        & button{
            padding: .5rem;
            padding-left: 2rem;
            padding-right: 2rem;
            color: white;
            border: none;
            background: rgb(173, 13, 173);

        }
    }
    & .text-2{
        font-size: 1.5rem;
        color: azure;
        margin-left: $margin;
    }
    & ul{
        /*border: solid red;*/
        margin-left: $margin;
        & li {
            margin-left: $margin;
        }
    }

}