@import "./Basic/Basic.scss";

$color-header: #242424;
$margin-element:3rem;
$text-header: white;
$text-logo:"beta";
$colo-fondo:#161515;
 

a{
  color: $text-header;
  text-decoration: none;
}

.Header-content{
  background: $colo-fondo;
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 95;
}
.Header{
  z-index: 99;
  width: 92%;
  color: $text-header;
  background: $color-header;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  margin-top: 1.2rem;
  margin-left: 4%;
  font-size: .9rem;
  border-radius: 10px;
  &-logo{
    margin-left: $margin-element;
  }
  &-logo h2{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-logo h2::after {
    content: $text-logo;
    font-weight: 100;
    font-size: .8rem;
    margin-top: 21px;
    margin-left: 2px;
    color: #d2d2cedc;
  }
  &-nav{
    margin-right: $margin-element;
  }
  &-nav ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 30px;
    cursor: pointer;
  }
  &-nav ul li:hover{
    transition: 0.5s;
  }
  &-nav ul li:active{ 
    filter: blur(3px);
  }
}

.on-link{
  transition: 0.5s;
  color: #b05dff;
  display: flex;
  align-items: center;
  justify-content: center;
    
}
.solid-on{
  display: none;
}

.solid{
  display: flex;
}
.Header-mostrar{
  display: flex;
}
.items-link{
  display: none;

}


@media (max-width: 700px){
  .rotateLevt{
    transform: rotate(180deg);
    transition: 0.75s;
  }
  .rotateNormal{
    transform: rotate(0deg);
    transition: 0.75s;
  }
  .solid-on{
    display: none;
  }
  .Header{
    &-logo{
      margin-left: $margin-element - 1rem;
    }
    
    &-nav{
      margin-right: $margin-element - 1rem;
    }
    
  }
  .items-link{
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #201f1f;
    gap: 5px;
    border-radius: 10px;
    padding: 5px;
    margin-top: 10px;
    li {
      list-style: none;
      background-color: #242424;
      padding-left: .8rem;
      padding-right: .8rem;
      padding-top: .4em;
      padding-bottom: .4em;
      border-radius: 10px;
      a:active{
        user-select: none;
        opacity: 0.2;
      }
    }
  }

}